<template>
  <report-guide-wrapper
    shaped
    title="Report Toolbar"
    style="top: 122px; right: 6px;"
  >
    <v-container
      color="white"
      class="pa-5"
    >
      <v-row v-if="!report.userBacked">
        <v-col class="body-2 d-flex align-baseline">
          <v-icon class="mr-4">
            mdi-view-list
          </v-icon>
          <p class="mb-0 align-self-center">
            With report views you can save report field selections, so you can quickly look at the
            insights you've found most helpful.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="body-2 d-flex align-baseline">
          <v-icon class="mr-4">
            mdi-arrow-expand
          </v-icon>
          <p class="mb-0 align-self-center">
            This will allow you to see only the report and hide the rest of the navigation.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="body-2 d-flex align-baseline">
          <v-icon class="mr-4">
            mdi-information-outline
          </v-icon>
          <p class="mb-0 align-self-center">
            Whenever you need to access this walkthrough again, you can do so by clicking or
            tapping the about icon.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="body-2 d-flex align-baseline">
          <v-icon class="mr-4">
            mdi-book-open-blank-variant
          </v-icon>
          <p class="mb-0 align-self-center">
            When a report has additional guides available, you can view them by clicking or
            tapping the guide icon.
          </p>
        </v-col>
      </v-row>
    </v-container>

    <slot name="navigator" />
  </report-guide-wrapper>
</template>

<script>
import { mapState } from 'vuex';
import ReportGuideWrapper from './ReportGuideWrapper';

export default {
  name: 'ToolbarGuide',
  components: {
    ReportGuideWrapper,
  },
  computed: {
    ...mapState('page/report', ['report']),
  },
};
</script>
