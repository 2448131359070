var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",style:(_vm.containerStyle),attrs:{"fluid":"","fill-height":""}},[_c('report-user-guide'),_c('v-overlay',{attrs:{"absolute":"","color":"grey lighten-4","value":!_vm.workbook.loaded}},[_c('v-progress-circular',{attrs:{"color":"secondary","indeterminate":"","size":"48"}})],1),_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[(_vm.workbook.loaded)?_c('report-header',{key:"report-header"}):_vm._e()],1),_c('div',{staticClass:"d-flex flex-row",style:({
      position: 'relative',
      width: '100%',
      height: `calc(100% - ${_vm.offsetReportBy}px)`
    })},[(_vm.report)?_c('sigma-report',{key:"sigma-report",style:({
        height: '100%',
        opacity: _vm.workbook.loaded ? 1 : 0,
        transition: 'opacity 0.25s ease-in'
      }),attrs:{"view":_vm.view,"report":_vm.report},on:{"workbook-variables-onchange":_vm.onChangeVariable,"workbook-loaded":_vm.onWorkbookLoaded}}):_vm._e(),_c('report-help-drawer',{attrs:{"value":_vm.help}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }