<template>
  <v-sheet
    ref="reportHeader"
    width="100%"
  >
    <report-views-drawer
      v-if="views.length > 0 && (systemUser || getProfile.impersonated)"
      ref="viewEditor"
    />

    <v-toolbar
      tile
      flat
      color="grey lighten-5"
    >
      <v-toolbar-title>
        <h1 class="text-h5 text-sm-h4">
          {{ report.title }}
        </h1>
      </v-toolbar-title>

      <v-spacer />

      <v-btn
        v-if="views.length > 0 && (systemUser || getProfile.impersonated)"
        dark
        :fab="$vuetify.breakpoint.xsOnly"
        :icon="$vuetify.breakpoint.xsOnly"
        depressed
        color="#2e7092"
        :class="{
          'mr-2': $vuetify.breakpoint.smAndUp
        }"
        @click="() => $refs.viewEditor.show()"
      >
        <v-icon
          :class="{
            'mr-2': $vuetify.breakpoint.smAndUp
          }"
        >
          mdi-view-list
        </v-icon>
        <span v-if="$vuetify.breakpoint.smAndUp">
          Views
        </span>
      </v-btn>

      <v-tooltip
        v-if="!expanded"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            fab
            icon
            v-bind="attrs"
            v-on="on"
            @click="onClickExpanded(true)"
          >
            <v-icon>
              mdi-arrow-expand
            </v-icon>
          </v-btn>
        </template>
        <span>Maximize</span>
      </v-tooltip>

      <v-tooltip
        v-if="expanded"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            fab
            icon
            v-bind="attrs"
            v-on="on"
            @click="onClickExpanded(false)"
          >
            <v-icon>
              mdi-arrow-collapse
            </v-icon>
          </v-btn>
        </template>
        <span>Minimize</span>
      </v-tooltip>

      <v-tooltip
        v-if="report.guide"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            fab
            icon
            v-bind="attrs"
            v-on="on"
            @click="onOpenHelp"
          >
            <v-icon>
              mdi-book-open-blank-variant
            </v-icon>
          </v-btn>
        </template>
        <span>Guide</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            fab
            icon
            v-bind="attrs"
            v-on="on"
            @click="() => $store.dispatch('page/report/setGuide', true)"
          >
            <v-icon>
              mdi-information-outline
            </v-icon>
          </v-btn>
        </template>
        <span>Tutorial</span>
      </v-tooltip>
    </v-toolbar>
    <v-divider />
  </v-sheet>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ReportViewsDrawer from './ReportViewsDrawer';

export default {
  name: 'ReportHeader',
  components: {
    ReportViewsDrawer,
  },
  data() {
    return {
      onNextHelpOpen: null,
    };
  },
  computed: {
    ...mapState('navigation', ['sidebar']),
    ...mapState('page/report', [
      'help',
      'report',
      'views',
      'expanded',
    ]),
    ...mapGetters([
      'systemUser',
      'getProfile',
    ]),
  },
  async created() {
    await this.$store.dispatch('page/report/fetchViews');
  },
  methods: {
    onOpenHelp() {
      const currentHelpValue = this.help;
      const nextHelpValue = !currentHelpValue;

      if (nextHelpValue) {
        window.setTimeout(() => {
          this.$store.dispatch('page/report/setHelp', nextHelpValue);
        }, 50);
      } else {
        this.$store.dispatch('page/report/setHelp', nextHelpValue);
      }

      if (typeof this.onNextHelpOpen === 'function') {
        window.setTimeout(() => {
          this.onNextHelpOpen();
          this.onNextHelpOpen = null;
        }, 50);
      }

      if (nextHelpValue && this.sidebar.open) {
        this.$store.dispatch('navigation/sidebar/setOpen', false);
        this.onNextHelpOpen = () => this.$store.dispatch('navigation/sidebar/setOpen', true);
      }
    },
    onClickExpanded(value) {
      this.$store.dispatch('page/report/setExpanded', value);
    },
  },
};
</script>
