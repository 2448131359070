<!-- eslint-disable vue/no-v-html -->
<template>
  <!-- There's an issue with this because it isn't at the app level -->
  <!-- Workaround is to adjust the width manually -->
  <v-navigation-drawer
    v-model="drawer"
    height="100%"
    :width="drawer ? '375px' : 0"
    overlay-color="transparent"
    absolute
    right
  >
    <!-- used to save a report -->
    <report-views-modal ref="viewsModal" />
    <confirm ref="deleteConfirm" />

    <!-- views header -->
    <v-toolbar
      flat
      tile
      class="grey lighten-4"
    >
      <v-toolbar-title class="d-flex align-center">
        <v-icon class="mr-2">
          mdi-view-list
        </v-icon>
        <span class="text-h5">Views</span>
      </v-toolbar-title>
      <v-spacer />

      <v-btn
        icon
        @click="hide"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-divider />

    <!-- views body content -->
    <v-card
      flat
      tile
      elevation="0"
    >
      <v-card-text>
        <strong>Current control values</strong>
        <div
          v-for="(value, key) in workbook.variables"
          :key="key"
        >
          <ul><li>{{ key }}<span v-html="formatValue(value)" /></li></ul>
        </div>
      </v-card-text>

      <v-divider />

      <v-sheet style="position: relative">
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              icon
              absolute
              top
              right
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                v-if="false"
                large
                class="white"
                color="green accent-5"
                @click="$refs.viewsModal.show"
              >
                mdi-plus-circle
              </v-icon>
            </v-btn>
          </template>
          <span>Save View</span>
        </v-tooltip>
      </v-sheet>
      <v-subheader
        style="height: 16px;"
        class="font-weight-medium py-5 grey lighten-5"
      >
        Saved Views
      </v-subheader>

      <v-divider />

      <!-- views conditional search -->
      <v-sheet
        v-if="showSearch"
        class="px-4 pt-4 pb-0 white"
      >
        <v-text-field
          v-model="search"
          label="Search Views"
          light
          flat
          solo
          dense
          hide-details
          clearable
          outlined
          color="grey lighten-1"
        />
      </v-sheet>

      <v-card-text class="pa-0">
        <!-- views progress indicator -->
        <v-sheet
          v-if="!hasFetchedViews"
          height="120px"
          class="d-flex align-center justify-center"
        >
          <v-progress-circular
            width="2"
            color="primary lighten-1"
            indeterminate
          />
        </v-sheet>

        <!-- views empty state -->
        <v-alert
          v-if="hasFetchedViews && views.length === 0"
          color="grey"
          class="ma-4"
          text
        >
          <span class="blue-grey--text darken-4 subtitle-2">No saved views.</span>
        </v-alert>

        <!-- views list -->
        <v-treeview
          v-if="hasFetchedViews"
          light
          shaped
          hoverable
          activatable
          class="report-view-tree"
          color="blue darken-4"
          :active="selected"
          :items="views"
          :search="search"
        >
          <template #label="{ item }">
            <div class="pl-2">
              <strong>{{ item.name }}</strong>
              <div
                v-for="(value, key) in filterVariables(item.variables)"
                :key="key"
                :style="{ backgroundColor: getColor(key, value) }"
              >
                <ul><li>{{ key }}<span v-html="formatValue(value)" /></li></ul>
              </div>
            </div>
          </template>
          <template #append="{ item }">
            <v-hover v-slot="{ hover }">
              <v-btn
                fab
                icon
                small
                plain
                @click.stop="onDelete(item)"
              >
                <v-icon :color="hover ? 'red accent-4' : 'red lighten-1'">
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-hover>
          </template>
        </v-treeview>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { del } from 'vue';
import { escape } from 'lodash';
import Confirm from '../../../components/Confirm';
import ReportViewsModal from './ReportViewsModal';

export default {
  name: 'ReportViewsDrawer',
  components: {
    Confirm,
    ReportViewsModal,
  },
  data() {
    return {
      hasFetchedViews: true,
      drawer: false,
      search: '',
      selected: [],
    };
  },
  computed: {
    ...mapState('page/report', ['views', 'view', 'workbook']),
    showSearch() {
      return this.views.length > 5;
    },
  },
  watch: {
    async drawer(newValue) {
      if (newValue && !this.hasFetchedViews) {
        await this.$store.dispatch('page/report/fetchViews');
        this.hasFetchedViews = true;
      }
    },
    view(newView) {
      if (newView) {
        this.selected = [newView.id];
      } else {
        this.selected = [];
      }
    },
  },
  methods: {
    ...mapActions('page/report', ['setView']),
    show() {
      this.drawer = true;
    },
    hide() {
      this.drawer = false;
    },
    async onDelete(view) {
      const acknowledged = await this.$refs.deleteConfirm.open(
        'Delete View',
        `Are you sure you want to delete the view ${view.name}?`,
        {
          color: 'secondary',
          width: '375px',
        },
      );

      if (acknowledged) {
        this.$store.dispatch('page/report/deleteView', view);
      }
    },
    onClickView([id]) {
      let collapse = false;

      if (!id) {
        collapse = true;
        this.setView(null);
      }

      if (id && (!this.view || this.view.id !== id)) {
        collapse = true;
        this.setView(this.views.find((v) => v.id === id));
      }

      if (collapse) {
        window.setTimeout(() => {
          this.hide();
        }, 150);
      }
    },
    filterVariables(variables) {
      del(variables, 'account-id');
      del(variables, 'brand-name');
      del(variables, 'user-id');
      return variables;
    },
    getColor(key, value) {
      if (this.formatValue(value) === this.formatValue(this.workbook.variables[key])) {
        return '#A5D6A7';
      }
      return null;
    },
    formatValue(value) {
      if (value) {
        const values = value.split(',').sort((a, b) => ((a > b) ? 1 : -1));
        return `<ul>${values.map((v) => `<li>${escape(decodeURIComponent(v))}</li>`).join('')}</ul>`;
      }
      return null;
    },
  },
};
</script>

<style scoped>
  .report-view-tree >>> .v-treeview-node {
    cursor: pointer;
  }
  .report-view-tree >>> .v-treeview-node__level {
    width: 0;
  }
  .report-view-tree >>> .v-treeview-node__content {
    margin-left: 0;
  }
  .report-view-tree >>> .v-treeview-node__label{
    white-space: normal !important;
  }
</style>
