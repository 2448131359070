import reset from '@/store/utils/reset';
import axios from '@/utils/axios';
import { sortBy } from 'lodash';
import dashboard from '../../_api/dashboard';
import workbook from './modules/workbook';

function initialState() {
  return {
    view: null,
    help: false,
    guide: false,
    expanded: false,
    views: [],
    report: {
      id: null,
      title: null,
      url: null,
      guide: null,
      userBacked: false,
    },
  };
}

// ---
export default {
  namespaced: true,
  state: initialState(),
  modules: {
    workbook,
  },
  mutations: {
    SET_VIEWS(state, views) {
      state.views = sortBy(Array.from(views), 'name');
    },
    SET_REPORT(state, report) {
      state.report = { ...state.report, ...report };
    },
    SET_VIEW(state, view) {
      if (view) {
        state.view = { ...state.view, ...view };
      } else {
        state.view = view;
      }
    },
    SET_HELP(state, help) {
      state.help = help;
    },
    SET_GUIDE(state, guide) {
      state.guide = guide;
    },
    RESET(state) {
      reset(state, initialState());
    },
    SET_EXPANDED(state, expanded) {
      state.expanded = expanded;
    },
  },
  actions: {
    async init(context, { accountType, reportSlug }) {
      const {
        rootState, dispatch, commit,
      } = context;

      const { activeAccount: { id: accountId } } = rootState.user;

      const [{ data }, hasViewedReportGuide] = await Promise.all([
        dashboard.getDashboard(
          accountType.toLowerCase(),
          accountId,
          reportSlug,
        ),
        dispatch('settings/get', 'hasViewedReportGuide', { root: true }),
      ]);

      commit('SET_GUIDE', !hasViewedReportGuide);
      commit('SET_REPORT', data);
    },

    async fetchViews({ rootState, state, commit }) {
      const { activeAccount: { id: accountId } } = rootState.user;

      const { data: { data } } = await axios.get(`/reports/${state.report.id}/accounts/${accountId}/views`);

      commit('SET_VIEWS', data);
    },

    async createView({ rootState, state, commit }, view) {
      const { activeAccount: { id: accountId } } = rootState.user;

      const { data: { data } } = await axios.post(
        `/reports/${state.report.id}/accounts/${accountId}/views`,
        view,
      );

      commit('SET_VIEWS', state.views.concat(data));

      return data;
    },

    async deleteView({ rootState, commit, state }, { id: viewId }) {
      const { activeAccount: { id: accountId } } = rootState.user;

      await axios.delete(`/reports/${state.report.id}/accounts/${accountId}/views/${viewId}`);

      commit('SET_VIEWS', state.views.filter((view) => view.id !== viewId));
    },

    setView({ state, commit, dispatch }, view) {
      if (view == null) {
        commit('SET_VIEW', view);
      } else if (!state.view || view.id !== state.view.id) {
        commit('SET_VIEW', view);
        dispatch('workbook/update', { variables: view.variables });
      }
    },
    setHelp({ commit }, help) {
      commit('SET_HELP', help);
    },
    setGuide({ commit }, guide) {
      commit('SET_GUIDE', guide);
    },
    reset({ commit, dispatch }) {
      commit('RESET');
      dispatch('workbook/reset');
    },
    setExpanded({ commit }, expanded) {
      commit('SET_EXPANDED', expanded);
    },
    logout: {
      root: true,
      handler({ dispatch }) {
        dispatch('reset');
      },
    },
  },
};
